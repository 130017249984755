@use "../abstracts/mixins";
@use "../base/breakpoints";

body,
p {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-base);
  color: var(--text-color);
}

button {
  font-family: inherit;
  font-size: inherit;
}

table,
td,
th,
form {
  font-size: var(--font-size-sm);
}

input,
textarea,
select {
  font-size: var(--font-size-sm);

  /* stylelint-disable-next-line media-query-no-invalid */
  @media (max-width: breakpoints.$tablet-breakpoint) {
    font-size: var(--font-size-base);
  }
}

// Reset monospaced font-size, because browsers reduce it by default to ~81%
pre,
code,
kbd,
samp,
tt {
  font-size: var(--font-size-monospace);
}

/*
 * Headings
 */

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  line-height: var(--line-height-heading);
  font-weight: 600;
  display: block;
  margin-top: 0;
  margin-bottom: var(--section-padding);
}

h1,
.h1 {
  font-size: 1.5rem;
}

h2,
.h2 {
  font-size: 1.375rem;
}

h3,
.h3 {
  font-size: 1.1875rem;
}

h4,
.h4 {
  font-size: 1rem;
}

h5,
.h5 {
  font-size: 0.8125rem;
}

h6,
.h6 {
  font-size: 0.625rem;
}

.jenkins-description {
  color: var(--text-color-secondary);
  line-height: 1.66;
  margin-top: 0;
  margin-bottom: var(--section-padding);
}

.jenkins-label {
  &--tertiary {
    color: var(--text-color-secondary);
    opacity: 0.7;
  }
}

a {
  @include mixins.link;
}

.jenkins-link--with-icon {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    width: 16px;
    height: 16px;
    color: var(--text-color) !important;
  }
}
